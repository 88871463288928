#oopss {
    background-size: cover;
    background-position: left;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
        url("https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/7a2114b9-8215-4579-8232-2de8d52f4405/d99zbf3-c2cc5114-894e-473d-a288-7d9bf027969b.jpg/v1/fill/w_1535,h_520,q_70,strp/shattered_rpg___market_of_lokoran_by_rhysgriffiths_d99zbf3-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9ODE0IiwicGF0aCI6IlwvZlwvN2EyMTE0YjktODIxNS00NTc5LTgyMzItMmRlOGQ1MmY0NDA1XC9kOTl6YmYzLWMyY2M1MTE0LTg5NGUtNDczZC1hMjg4LTdkOWJmMDI3OTY5Yi5qcGciLCJ3aWR0aCI6Ijw9MjQwMCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.PJy_jlBcXzy6GWuKtzKalUwZOwymjpYdZzBNuyTTMp0");
    position: fixed;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 1.5em;
    z-index: 9999;
}
#oopss #error-text {
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Shabnam", Tahoma, sans-serif;
    color: white;
    direction: rtl;
}
#oopss #error-text img {
    color: #fff300;
    margin: 85px auto 20px;
    height: 342px;
}
#oopss #error-text span {
    position: relative;
    font-size: 3.3em;
    font-weight: 900;
    margin-bottom: 50px;
}
#oopss #error-text p.p-a {
    font-size: 19px;
    margin: 30px 0 15px 0;
}
#oopss #error-text p.p-b {
    font-size: 15px;
}
#oopss #error-text .back {
    background: #ffffff;
    color: #000;
    font-size: 30px;
    text-decoration: none;
    margin: 2em auto 0;
    padding: 0.7em 2em;
    border-radius: 500px;
    box-shadow: 0 20px 70px 4px rgba(0, 0, 0, 0.1), inset 7px 33px 0 0px #fff300;
    font-weight: 900;
    transition: all 300ms ease;
}
#oopss #error-text .back:hover {
    -webkit-transform: translateY(-13px);
    transform: translateY(-13px);
    box-shadow: 0 35px 90px 4px rgba(0, 0, 0, 0.3), inset 0px 0 0 3px #000;
}

@font-face {
    font-family: Shabnam;
    src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.eot");
    src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.woff")
            format("woff"),
        url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.woff2")
            format("woff2"),
        url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam-Bold.ttf")
            format("truetype");
    font-weight: bold;
}
@font-face {
    font-family: Shabnam;
    src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.eot");
    src: url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.eot?#iefix")
            format("embedded-opentype"),
        url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.woff")
            format("woff"),
        url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.woff2")
            format("woff2"),
        url("https://cdn.rawgit.com/ahmedhosna95/upload/ba6564f8/fonts/Shabnam/Shabnam.ttf")
            format("truetype");
    font-weight: normal;
}
