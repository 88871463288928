.modal-dialog {
    width: 80% !important;
    max-width: none !important;
}

textarea,
input {
    width: 80% !important;
    display: block !important;
    margin-bottom: 10px !important;
}
.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px; // set a maximum width to maintain layout integrity on larger screens
    margin: 0 auto;
    h1 {
        color: white;
        text-align: center;
        margin: 15px;
    }
}
.form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; // align buttons vertically
    margin: 0.5rem;
}

.form-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; // align buttons vertically
    margin: 0.5rem;

    button {
        width: 100%;
        padding: 15px;
        font-size: 1em;
        border: none;
        border-radius: 10px;
        background-color: #3f51b5;
        color: rgb(240, 238, 238);
        cursor: pointer;

        &:hover {
            background-color: #283593;
        }
    }
}

.edit-form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; // align buttons vertically
    margin: 0.5rem;
    padding: 0à.6rem;

    button {
        width: 100%;
        padding: 15px;
        font-size: 1em;
        border: none;
        border-radius: 10px;
        background-color: #0bc604c0;
        color: rgb(22, 22, 22);
        cursor: pointer;

        &:hover {
            background-color: #1abd40;
        }
    }
}
