$font-main: poppins;
$color-light-grey: rgba(1, 1, 1, 0.845);
$color-dark-grey: rgba(128, 128, 128, 0.358);
$color-background: #e8e8e8;
$color-shadow: rgba(0, 0, 0, 0.2);
$color-light: rgb(224, 228, 228);
$color-dark: rgb(213, 213, 213);
$color-ribbon: #f50404e1;

body {
    margin: 0;

    // background-size: cover;
    // background-position: bottom;
    // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)),
    //     url("https://images.unsplash.com/photo-1615806647582-53fea422c9b4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2076&q=80");
}

@font-face {
    font-family: "Vecna";
    src: local("Vecna"), url("./Fonts/Vecna/Vecna-oppx.ttf") format("truetype");
}

@font-face {
    font-family: "Diablo";
    src: local("Diablo"), url("./Fonts/Diablo/avqest.ttf") format("truetype");
}

@font-face {
    font-family: "Morpheus";
    src: local("Morpheus"),
        url("./Fonts/Morpheus/MORPHEUS.TTF") format("truetype");
}
@font-face {
    font-family: "Hill_House";
    src: local("Hill_House"), url("./Fonts/Essai/Hill_House.ttf");
}

@font-face {
    font-family: "Fire";
    src: local("Fire"), url("./Fonts/Fire/fire.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* Pour changer le curseur par défaut */

/* Pour changer le curseur du pointeur (comme sur les liens) */
