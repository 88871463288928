@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Kanit:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fresca&display=swap");
@import url("https://fonts.googleapis.com/css2?family=McLaren&display=swap");
@import "../index.scss";
.nav-icon {
    margin: 0 auto;
    border-radius: 100%;
    width: 350px;
}
.home-container {
    font-family: poppins;
    background-color: #e8e8e8;
    color: rgb(24, 24, 24);
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
    padding-bottom: 150px;
}

.home-container strong {
    font-family: "Bebas Neue", sans-serif;
    margin: 1.5rem auto;
    text-align: center;
    font-size: 2.75rem;
    display: inline;
}

.home-container h1 {
    font-family: "Bebas Neue", sans-serif;
    margin: 1.5rem auto;
    text-align: center;
    font-size: 1.75rem;
}

.affiliation_buttons {
    height: 5rem;
    margin: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Centrer tous les enfants verticalement */
    text-align: center;
}

 .logo_drivethrurpg, .logo_amazon {
    margin: 0 10px;
    height: 3.5rem; /* Hauteur uniforme pour tous les logos */
    width: 12rem;
    object-fit: contain; /* Garde l'aspect ratio des images */
}
.logo_philibert {
     margin: 0 10px;
    height: 3.5rem; /* Hauteur uniforme pour tous les logos */
    width: 12rem;
    object-fit: contain; /* Garde l'aspect ratio des images */
}

.introduction {
    width: 80%;
    margin-top: 30px;
    padding: 20px; /* Ajout de padding pour un espacement interne */
}

.paragraphs {
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Assure que les divs s'étendent à la largeur totale */
}
.title-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo {
        width: 200px;
        height: auto;
    }
}

.right,
.left {
    width: 100%;
}

.right p {
    margin-left: 20%; /* Pousse les paragraphes de droite vers la droite */
}

.left p {
    margin-left: 20%; /* Pousse les paragraphes de gauche vers la gauche */
}

.introduction h1 {
    // font-family: "Homemade Apple", cursive;
    font-family: $font-main;
    font-size: 2.8rem; /* Augmentation de la taille pour le titre */
    font-weight: 600;
    margin-bottom: 45px; /* Espacement supplémentaire sous le titre */
}

.introduction p {
    font-family: $font-main;
    margin: 25px 0;
    text-align: justify;
    color: #666;
    font-size: 1.2em;
    line-height: 1.3em;
    margin-bottom: 10px;
}

.introduction .keyword {
    // color: #fc7702; /*
    font-weight: bold;
    font-size: 1.2rem;
    // font-family: "Homemade Apple", cursive;
    // font-family: "Diablo", cursive;
    font-family: $font-main;
    animation-name: glow;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.introduction strong {
    font-size: 1.2rem;
    font-weight: bold;
    // font-family: "Homemade Apple", cursive;
    font-family: $font-main;
    // font-family: "Diablo", cursive;
    // color: #3632bbca;
    animation-name: glowred;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.link_philibert {
    font-size: 1.2rem;
    transition: transform 0.3s;
    margin-right: 4px;
    font-weight: 600;
    color: #d47502;

    &:hover {
        transform: scale(1.3);
        color: rgba(9, 9, 9, 0.995);
    }
}
.link_drivreThruRpg {
    font-size: 1.2rem;
    transition: transform 0.3s;
    margin-right: 4px;
    font-weight: 600;
    color: #d40202e6;

    &:hover {
        transform: scale(1.3);
        color: rgba(9, 9, 9, 0.995);
    }
}
.link_amazon {
    font-size: 1.2rem;
    transition: transform 0.3s;
    margin-right: 4px;
    font-weight: 600;
    color: #333;

    &:hover {
        transform: scale(1.3);
        color: rgba(247, 167, 5, 0.995);
    }
}
.signature {
    display: flex;
    justify-content: flex-end;
    strong {
        display: flex;
        font-size: 2.5rem;
        justify-content: flex-end;
    }
}
.logo_gameover {
    width: 150px;
}
.btn-home {
    width: auto;
    height: auto;
    background-color: #f79502;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: $font-main;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
        7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    outline: none;
}
.hidden-games {
    border: none;
    color: #000000;
    font-size: 1.2rem;
}
.hidden-games:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
}
.hidden-games:hover {
    color: #000;
}
.hidden-games:hover:after {
    left: auto;
    right: 0;
    width: 100%;
}
.hidden-games:active {
    top: 2px;
}
.search-bar {
    display: flex;
    width: 60%;
    align-items: center;
    flex-direction: row;
    margin-right: 1rem;
    input {
        font-family: poppins;
        margin: 10px;
        width: 100%;
        border-radius: 10px;
        padding: 2px;
        text-align: center;
    }
    input::placeholder {
        // padding-left: 8px;
        text-align: center;
    }
    select {
        font-family: poppins;
        margin: 15px;
        width: 60%;
        padding: 2px;
        border-radius: 10px;
    }
}
.search-bar button {
    background-color: #f79502;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
    font-family: "Bebas Neue", sans-serif;
    transition: background-color 0.3s ease;
}

.search-bar button:hover {
    background-color: #d47502;
}
.search_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 1.5rem;
}

.container {
    padding: 20px;
    // background-color: rgba(253, 253, 253, 0.359);
    // border-radius: 15px;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    // margin-bottom: 5rem;
    // margin-top: 2.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 150px;
}
a {
    color: inherit;
    text-decoration: none;
}
a:hover {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

h1 {
    font-size: 2.5rem;
    font-family: "Bebas Neue", sans-serif;
    // margin: 1.3rem;
    // padding-bottom: 2px;
}

h2 {
    font-size: 2.5rem;
    font-family: "Bebas Neue", sans-serif;
    margin-top: 1.3rem;
    padding-bottom: 2px;
}
h3 {
    font-size: 2.5rem;
    font-family: "Bebas Neue", sans-serif;
    margin: 1.5rem;
    // border-bottom: 1px solid black;
    padding-bottom: 10px;
}
.button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.infos_card {
    display: flex;
    flex-direction: row;
    p {
        padding: 5px;
    }
}

@media (max-width: 767px) {
    .home-container {
        .introduction {
            width: 95%;
        }

        .search-bar {
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: column;
            margin-right: 1rem;
            input {
                font-family: poppins;
                margin: 10px;
                width: 100%;
                border-radius: 10px;
            }
            select {
                font-family: poppins;
                margin: 15px;
                width: 50%;
                border-radius: 10px;
            }
        }
    }
}
.game_critics {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: auto;
    margin: 20px auto;
    border: 1px solid #aaa;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.positif_points,
.negatif_points {
    list-style-type: none;
    margin: 10px 0;
}

.positif_points_p,
.negatif_points_p {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.critics ul {
    // list-style-type: none;
    padding: 0;
    margin: 15px 45px;
}

.critics li {
    margin-bottom: 5px;
}

.critics p {
    margin: 0;
}

// .game_critics {
//     width: 60%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 25px;
//     padding: 25px;
//     border: solid 2px black;
// }

// .game_critics ul {
//     font-size: 2rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: flex-start;
// }
// .game_critics p {
//     font-size: 1.5rem;
// }

// .positif_points {
//     list-style-type: none;
// }

// // .critics {
// //     margin: 0px 0px 15px 25px;
// // }
// // .critics ul li {
// //     margin: 0px 0px 15px 25px;
// //     text-decoration: none;
// // }
// .positif_points_p {
//     font-size: 1.5rem;
//     font-weight: 700;
//     border-bottom: solid 1px black;
// }
// @media (max-width: 767px) {
//     .critics p {
//         border: 2px solid red;
//         font-size: 2px;
//         font-family: poppins;
//     }
//     .div-c {
//         border: 2px solid orangered;
//         display: none;
//     }
// }
// .critics {
//     border: 2px solid yellow;
//     display: none;
// }
