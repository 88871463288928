// AddGameForm.scss

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px; // set a maximum width to maintain layout integrity on larger screens
    margin: 0 auto;
    h1 {
        text-align: center;
        margin: 15px;
    }
}

.form-button {
    display: flex;
    flex-direction: row;
    select {
        width: 25%; // full width
        padding: 10px;
        font-size: 1em;
        border-radius: 5px;
        border: 3px solid #49b53f;
        margin-bottom: 20px; // space below each form field
    }
}

.edit-form-buttons {
    display: flex;
    flex-direction: row;
    select {
        width: 25%; // full width
        padding: 10px;
        font-size: 1em;
        border-radius: 5px;
        border: 3px solid #f70606;
        margin-bottom: 20px; // space below each form field
    }
}

.game-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start; // align form fields at the start
    justify-content: flex-start; // align all elements at the top
    width: 80%; // set width of the form to 80%
    margin: 0 auto; // center the form horizontally

    label {
        width: 100%; // full width
        margin-bottom: 10px; // space below the label
    }

    input,
    select,
    textarea {
        width: 100%; // full width
        padding: 10px;
        font-size: 1em;
        border-radius: 5px;
        border: 1px solid #3f51b5;
        margin-bottom: 20px; // space below each form field
    }

    textarea {
        resize: vertical;
        min-height: 100px;
    }
    .radio-group {
        display: flex;
        border: 2px solid black;
        // justify-content: space-around;
        // width: 100%;
    }
    .submit-button {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px; // Ajouter une marge en haut si nécessaire
    }

    button {
        width: 80%; // match the width of form fields
        padding: 10px;
        font-size: 1em;
        border: none;
        border-radius: 5px;
        background-color: #3f51b5;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: #283593;
        }
    }

    .button {
        width: 80%; // match the width of form fields
        padding: 10px;
        font-size: 1em;
        border: none;
        border-radius: 5px;
        background-color: #3f51b5;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: #283593;
        }
    }
}

// Responsive
@media (max-width: 600px) {
    .form {
        width: 90%;
    }

    .form-buttons {
        flex-direction: column; // stack buttons on smaller screens
        button {
            width: 100%; // make buttons full width on smaller screens
        }
    }

    .game-form {
        input,
        select,
        textarea,
        button, // make button full width on smaller screens
        .button {
            // make button full width on smaller screens
            width: 100%;
        }
    }
}
