@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Kanit:wght@300&display=swap");

.main_container {
    font-family: "Bebas Neue", sans-serif;
    background-size: cover;
    background-position: 0% 83%;
    background-image: linear-gradient(
            rgba(0, 0, 0, 0.492),
            rgba(3, 3, 3, 0.452)
        ),
        url("https://i.postimg.cc/Bbp4kdBx/biblioteque-1.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;

    a {
        font-weight: 500;
        text-decoration: none;
        margin: 10px;
        color: white;
        font-family: "Kanit", sans-serif;

        span {
            font-family: "Bebas Neue", sans-serif;
            font-size: medium;
            text-decoration: none;

            h1 {
                line-height: 1rem;
                font-family: "Morpheus", sans-serif;
                p {
                    color: white;
                }
            }
        }
    }
}

.logo_main_container {
    display: flex;
    width: 80vw;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.logo_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo,
.logo_title {
    height: 8rem;
    margin-right: 3rem;
}

.logo_title {
    margin-left: 20px;
}

.title {
    font-size: 3.5rem;
    margin: 10px;
    padding-left: 10px 0px 10px 40px;
    font-family: "Bebas Neue", sans-serif;
}


.navbar_container {
    display: flex;
    flex-direction: row;
    width: 100vw;
}

.navbar-nav {
    display: flex;
    align-items: center;
    font-family: "Bebas Neue", sans-serif;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-top: 1px solid #424242d4;
    border-bottom: 1px solid #a9a8a8d4;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2), 0px -2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
    top: 0;
    z-index: 100;
}

.nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;

    .nav-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.19rem;
        margin: 0 50px;
        color: white;
        transition: transform 0.3s;

        .nav-icon {
            width: 50px;
            height: auto;
            margin-right: 10px;
        }

        &:hover {
            transform: scale(1.3);
            color: rgba(255, 255, 255, 0.995);
        }
    }
}

.nav-link-blog {
    border: 2px solid orange;
    background-color: #ed980f2e;
}

@media (min-width: 767px) and (max-width: 1535px) {
    .navbar_container {
        display: flex;
        flex-direction: row;
    }
    .nav-icon {
        display: none;
    }
    .navbar-nav .nav-links .nav-link {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 0.8rem;
        margin: 0 12px;
        color: white;
    }
}

@media (max-width: 767px) {
    .main_container {
        padding-left: 15px;
        // height: 80px;
    }
    .logo_main_container,
    .navbar_container {
        display: flex;
        width: 100%;
        max-height: 70vh;

        align-items: flex-start;
        justify-content: space-around;
        padding: 0px 6px 0px 6px;
    }

    .navbar-collapse {
        padding: 0;
    }

    .logo,
    .logo_title {
        display: none;
    }

    .title {
        font-size: 2.5rem;
        padding-left: 25px;
        margin: 0;
    }

    .navbar-nav {
        flex-direction: column;
        margin-top: 20px;

        .nav-links {
            display: flex;
            flex-direction: column;
            align-items: center;
            list-style: none;
            padding: 0;
            width: 100%;

            .nav-link {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 16px;
                margin: 10px 0;
                width: 100%;

                .nav-icon {
                    width: 40px;
                    height: auto;
                    margin-left: 10px;
                }
            }
        }
    }
}
