.construction-page {
    background-color: #333;
    background-image: url("../../../public/images/404.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    text-align: center;
    padding: 50px;
    background-color: rgba(255, 255, 255, 0.8);

    h1 {
        color: #333;
        font-size: 32px;
        margin-bottom: 20px;
    }

    p {
        color: #666;
        font-size: 18px;
        margin-bottom: 40px;
    }
}
@media (max-width: 767px) {
    .construction-page {
        background-position: right;
    }
}
