.main-para {
    text-align: justify;
    margin: 20px auto;
    max-width: 800px;
    min-height: 100vh;
    padding: 20px;

    h1 {
        color: #333;
        font-size: 2.5em;
        margin-bottom: 20px;
        padding: 20px;
    }

    h2 {
        color: #666;
        font-size: 2em;
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 20px;
    }

    p {
        color: #666;
        font-size: 1.2em;
        line-height: 1.5em;
        margin-bottom: 10px;
    }

    ul {
        padding-left: 20px;

        li {
            color: #666;
            font-size: 1.2em;
            line-height: 1.5em;
            margin-bottom: 10px;
        }
    }
}
.bold_text {
    font-weight: 600;
    color: black;
}
.team {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    ul {
        list-style-type: none;
    }

    h5 {
        font-size: 2rem;
    }
    .guillaume {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 5px;
        img {
            margin: 5px;
            width: 250px;
            height: 270px;
            border-radius: 100%;
        }
    }
    .alexandre {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 5px;
        img {
            margin: 5px;
            width: 250px;
            height: 270px;
            border-radius: 100%;
        }
    }
    .baptiste {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 5px;

        img {
            margin: 5px;
            width: 250px;
            height: 270px;
            border-radius: 100%;
        }
    }
}
@media (max-width: 767px) {
    .team {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
