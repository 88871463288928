@import "../index.scss";
.trad_ribbon {
    font-family: "Diablo", sans-serif;
    --f: 10px; /* contrôle la partie pliée */
    --r: 15px; /* contrôle la forme du ruban */
    --t: 40px; /* décalage supérieur */
    position: absolute;
    inset: var(--t) calc(-1 * var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - var(--f)),
        calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),
        0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f) / 2)
    );
    background: linear-gradient(to right, #0138ed, #ffffff, #ff0000);
    color: rgb(0, 0, 0);
    font-weight: 1000;
    font-size: 1.1rem;
    box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
    text-align: center;
    -webkit-text-stroke: 0.1px rgba(252, 252, 252, 0.252);
}
.try_red {
    background-color: red;
}

.ribbon {
    font-family: "Diablo", sans-serif;
    font-size: 1.25rem;
    --f: 10px; // control the folded part
    --r: 15px; // control the ribbon shape
    --t: 10px; // the top offset
    position: absolute;
    inset: var(--t) calc(-1 * var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - var(--f)),
        calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),
        0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f) / 2)
    );
    background: $color-ribbon;
    color: white;
    box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
    // font-weight: 1000;
}
.circle-flag {
    --d: 40px; /* diamètre du cercle */
    position: absolute;
    right: 15px; /* décalage à droite */
    bottom: 15px; /* décalage en bas */
    width: var(--d);
    height: var(--d);
    border-radius: 50%; /* pour rendre le div en forme de cercle */
    background: linear-gradient(
        to right,
        #0138ed 33.33%,
        #ffffff 33.33% 66.67%,
        #ff0000 66.67%
    ); /* bleu, blanc, rouge */
    box-shadow: 0 0 0 5px #0005; /* ombre autour du cercle */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* pour s'assurer que l'image ne déborde pas du cercle */
}

.circle-flag img {
    max-width: 90%; /* ajustez ceci en fonction de la taille souhaitée de l'image à l'intérieur du cercle */
    height: auto;
    margin: 0 auto;
}

.wrapper {
    display: flex;
    flex-wrap: wrap; // Pour permettre le défilement en ligne
    justify-content: space-around;
    margin: 0 auto; // Centre le wrapper sur la page
    max-width: 1200px; // Par exemple pour fixer une largeur max au wrapper
}

.card {
    position: relative;
    width: 280px;
    height: 360px;
    border-radius: 15px;
    padding: 1.5rem;
    background: white;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    transition: 0.4s ease-out;
    margin: 0.7rem;
    box-shadow: 0px 7px 10px rgba(black, 0.5);

    &:hover {
        transform: translateY(20px);

        &:before {
            opacity: 1;
        }

        .info {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background: rgba(rgba(0, 0, 0, 0.734), 0.8);
        z-index: 2;
        transition: 0.5s;
        opacity: 0;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 15px;
    }

    .info {
        display: flex;
        flex-direction: column;

        position: absolute;
        z-index: 3;
        color: rgb(235, 235, 235);
        opacity: 0;
        transform: translateY(30px);
        transition: 0.5s;
        padding: 0 1rem 0 1rem;

        .infos_card {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            flex-direction: column;
            align-items: flex-start;
        }
        .infos_card_up {
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;
        }
        .infos_card_down {
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;
        }
        .infos_card span {
            margin-left: 8px;
            font-size: 0.8rem;
            // flex-wrap: nowrap;
        }
        .infos_card .difficulty {
            // margin-left: 4px;
            // margin-right: 8px;
            margin-bottom: 2px;
            font-size: 0.8rem;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            // flex-wrap: nowrap;
            justify-content: center;
        }
        .infos_card .author {
            margin-bottom: 2px;
            font-size: 0.8rem;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            // flex-wrap: nowrap;
            justify-content: center;
        }
        .infos_card .release_date {
            margin-bottom: 2px;
            font-size: 0.8rem;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            // flex-wrap: nowrap;
            justify-content: center;
        }
        .infos_card .univers {
            margin-bottom: 2px;
            font-size: 0.8rem;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            flex-wrap: nowrap;
            justify-content: center;
        }
        .trad_message p {
            position: relative;
            color: rgb(3, 3, 3);
            text-align: center;
            border-radius: 5px;
            overflow: hidden;
        }

        .trad_message p::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: linear-gradient(
                to right,
                #0138ed 33.33%,
                #ffffff 33.33% 66.67%,
                #ff0000 66.67%
            );
        }

        .trad_message p::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.4);
            z-index: 2;
        }
        .text {
            position: relative;
            z-index: 3;
        }

        h1 {
            margin: 0 0 10px 0;
            letter-spacing: 2px;
            font-size: 1.8rem;
            font-weight: 700;
            color: white;
        }

        p {
            letter-spacing: 1px;
            line-height: 1.2;
            font-size: 0.8rem;
            margin-top: 8px;
            font-weight: 500;
            overflow: hidden;
        }

        button {
            padding: 0.6rem;
            outline: none;
            border: none;
            border-radius: 3px;
            background: white;
            color: black;
            font-weight: bold;
            cursor: pointer;
            transition: 0.4s ease;

            &:hover {
                background: dodgerblue;
                color: white;
            }
        }
    }
}
