.game-detail {
    font-family: poppins;
    text-align: center;
    background-color: #333;
    // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)),
    //     url("https://images.unsplash.com/photo-1578377375762-cbcc98d68af0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    padding: 20px;
    color: rgb(224, 228, 228);
}

.game-detail img {
    max-width: 70%;
    border-radius: 10px;
    height: auto;
    margin: 0 auto;
    box-shadow: 23px 24px 32px 26px rgba(255, 255, 255, 0.104);
}

.game-description {
    font-family: poppins;
    font-size: 1rem;
    line-height: 1.6;
    margin: 15px auto;
    width: 80%;
}

.game-description h2,
.game-description p {
    font-family: poppins;
    margin-top: 15px;
}

.game-description p {
    text-align: justify;
    font-size: 16px;
    font-family: poppins;
    margin-bottom: 15px;
}

h3 {
    font-family: poppins;
    margin: 10px;
    font-size: 1.8rem;
}
h4 {
    font-family: poppins;
    margin: 10px;
    font-size: 2rem;
}

.text-paragraph {
    margin: 10px;
    text-align: justify;
}
.text-paragraph_em {
    margin: 10px;
    text-align: justify;
}
@media (max-width: 767px) {
    .game-description {
        font-size: 1rem;
        line-height: 1.6;
        margin: 15px auto;
        width: 95%;
    }
    .game-description p {
        text-align: left;
    }
    .game-detail {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .text-paragraph {
        margin-left: 5%;
        margin-right: 5%;
        text-align: justify;
    }
}
