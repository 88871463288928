.footer {
    width: 100%;
    background-color: #222;
    color: #ccc;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    // position: fixed; /* Fixe la position du pied de page */
    // bottom: 0;
}

.footer h4 {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer ul li {
    margin-bottom: 10px;
}

.footer ul li a {
    color: #ccc;
    text-decoration: none;
}

.footer ul li a:hover {
    color: #fff;
    text-decoration: none;
}

.footer ul li i {
    margin-right: 10px;
}

.footer hr {
    border-color: #333;
    margin-top: 20px;
    margin-bottom: 20px;
}

.footer .text-muted {
    margin-top: 20px;
    text-align: center;
}
.contact_email {
    font-size: 1.2rem;
    text-decoration: underline;
}
.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons a {
    margin-right: 20px;
    font-size: 24px;
}
// .social-icons a.discord {
//     color: #7289da; /* couleur violette de Discord */
// }

// .social-icons a.facebook {
//     color: #1877f2; /* couleur bleue de Facebook */
// }

// .social-icons a.youtube {
//     color: #ff0000; /* couleur rouge de YouTube */
// }
